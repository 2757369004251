<template>
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.49878 7.88477V4.63477M5.87378 6.25977H9.12378M4.79045 10.3223V11.5873C4.79045 11.876 4.79045 12.0203 4.84961 12.0944C4.90107 12.1589 4.97909 12.1964 5.06157 12.1963C5.15641 12.1962 5.2691 12.106 5.49448 11.9257L6.7866 10.892C7.05056 10.6808 7.18254 10.5753 7.3295 10.5002C7.45989 10.4336 7.59868 10.3849 7.7421 10.3554C7.90377 10.3223 8.07278 10.3223 8.41081 10.3223H9.77378C10.6839 10.3223 11.1389 10.3223 11.4865 10.1452C11.7923 9.98936 12.0409 9.74076 12.1967 9.435C12.3738 9.08739 12.3738 8.63235 12.3738 7.72227V4.79727C12.3738 3.88718 12.3738 3.43214 12.1967 3.08453C12.0409 2.77877 11.7923 2.53017 11.4865 2.37438C11.1389 2.19727 10.6839 2.19727 9.77378 2.19727H5.22378C4.31369 2.19727 3.85865 2.19727 3.51104 2.37438C3.20528 2.53017 2.95669 2.77877 2.80089 3.08453C2.62378 3.43214 2.62378 3.88718 2.62378 4.79727V8.1556C2.62378 8.65933 2.62378 8.9112 2.67915 9.11785C2.82941 9.67862 3.26742 10.1166 3.8282 10.2669C4.03484 10.3223 4.28671 10.3223 4.79045 10.3223Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconMessagePlusSquare'
}
</script>
